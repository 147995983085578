//@import "~bootstrap/scss/variables";
$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;

$font-size-base: 0.85rem;
$dark: #151515;
$primary: #ddd;
$white: #fafafa;
$input-bg: #f1f1f1;
$light: #f7f7f7;

$body-bg: rgba(10, 10, 10, 0.95);
$body-color: #ddd;

$input-color-placeholder: $gray-600;
$component-active-color: $gray-800;

$dropdown-link-hover-bg: $gray-900;
$dropdown-link-hover-color: $gray-100 !important;
$dropdown-link-active-bg: $gray-700;
$dropdown-link-active-color: $gray-200;
$dropdown-color: rgba(50, 50, 50, 0.8);

@import "~bootstrap/scss/bootstrap";
@import "~bootstrap-select/sass/bootstrap-select";

/* Global
----------------------------*/

html {
  position: relative;
  min-height: 100%;
}

body {
  margin-bottom: calc(60px + 2rem);
  background: url("/dist/images/background/202.jpg") no-repeat center center fixed;
  background-size: cover;

}

section {
  margin: 30px 0;
}

h3 {
  margin-bottom: 1rem;
}

body > .container {
  background: $body-bg;
  padding: 1rem;
  margin: 2rem auto;
}

.container-front-page {
  background: linear-gradient(
                  #adadad,
                  #545454
  );

  .border {
    border-color: #000 !important;
  }
}

body > footer {
  background: $body-bg;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 60px;
  line-height: 60px;
}

a {
  color: lighten($blue, 20%);
}
/* Navigation
----------------------------*/

.nav-main {
}

.dropzone {
  border: 2px dashed #dedede;
  border-radius: 5px;
  background: #f5f5f5;
}

.dropzone i {
  font-size: 5rem;
}

.dropzone .dz-message {
  color: rgba(0, 0, 0, .54);
  font-weight: 500;
  font-size: initial;
  text-transform: uppercase;
}
